

























import { Component, Vue } from "vue-property-decorator";
import '@/assets/css/user/main.css';
import {EMAIL} from "@/interface/email";
import AUTH_TYPE = EMAIL.AUTH_TYPE;

@Component
export default class AuthFail extends Vue {

  $refs!: { [key:string]: any }

  pending: boolean;
  userEmail: string;

  constructor() {
      super();
      this.pending = false;
      this.userEmail = this.$route.params.email;
  }

  created () {
    const isLogin = this.$store.getters.isLogin;
    if (isLogin) this.$router.push('/project/list');
    this.authEmailSend();
  }

  mounted(){
  }

  async authEmailSend() {
    const sendData = {
      email:this.userEmail,
      type : AUTH_TYPE.RE_AUTH,
    };

    const {data} = await Vue.axios({
      url: '/mail/authEmailSend',
      method: 'POST',
      data : sendData
    });
    if(data.result){
      await Vue.$toast.open({
         message: data.message,
         type: 'warning',
         duration: 10000
      });
    }

  }

}
